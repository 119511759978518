<template>
  <div>
    <v-layout wrap justify-center>
      <v-layout wrap justify-start :style="' background-color: #766BC0 '">
        <v-flex xs12 py-2 :style="' color: white '">
          <!-----------------------------------xs header------------------------------------------>
          <v-layout wrap class="hidden-md-and-up">
            <v-flex xs1 align-self-center>
              <v-app-bar-nav-icon
                style="color: white"
                @click="nav = true"
              ></v-app-bar-nav-icon>
              <v-navigation-drawer
                height="450px"
                absolute
                temporary
                v-model="nav"
              >
                <v-layout class="menufont1" wrap justify-center pa-3>
                  <v-flex xs12>
                    <v-list dense nav>
                      <v-list-item
                        v-for="item in items"
                        :key="item.title"
                        link
                        :to="item.route"
                      >
                        <v-list-item-icon>
                          <v-img height="20px" contain :src="item.src"></v-img>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-navigation-drawer>
            </v-flex>
            <v-flex
              xs6
              sm8
              class="text-left pl-2 pt-1 nsbold"
              style="font-size: 18px; font-weight: 500"
              >SVBH
            </v-flex>
            <v-flex xs3 sm2 px-4>
              <v-btn class="nsbold white--text" text @click="appLogout()">
                <span :style="'color:white'"> LOGOUT </span>
              </v-btn>
            </v-flex>
          </v-layout>
          <!------------------------------------------------------------------------------>
          <v-layout wrap justify-space-around class="hidden-sm-and-down">
            <v-flex
              xs3
              md9
              lg10
              align-self-center
              class="text-left pl-2 nsbold"
              style="font-size: 18px; font-weight: 500"
              >St.Vincent Boarding House
            </v-flex>
            <!-- <v-flex xs lg7 px-4 text-center>
              <v-layout wrap justify-center>
                <v-flex xs12 align-self-center>
                  <v-btn
                    active-class="change"
                 style="color:white"
                  v-for="(item,i) in card" :key="i"
                class="btn nsbold white--text"
                @click="$router.push({ name: item.route.name })"
                text
                tile
                >
              <span :style="'color:white'">
                {{ item.name }} 
              </span>
                </v-btn
              >
                </v-flex>
              </v-layout>
            </v-flex> -->

            <v-flex xs2 lg2 px-4 text-right>
              <!-- <v-btn class="nsbold white--text" text @click="appLogout()">
                <span :style="'color:white'">
                LOGOUT
              </span>
              </v-btn> -->
              <v-menu offset-y left>
                <template v-slot:activator="{ attrs, on }">
                  <v-layout wrap justify-center v-bind="attrs" v-on="on">
                    <v-flex xs12 text-center align-self-center>
                      <v-icon
                        size="25"
                        title="Account"
                        :style="
                          $route.path !== '/admin'
                            ? 'color:white'
                            : 'color:white'
                        "
                      >
                        mdi-account
                      </v-icon>
                    </v-flex>
                    <v-flex
                      class="hidden-xs-only"
                      xs12
                      text-center
                      align-self-center
                      style="line-height: 0.5"
                      pt-1
                    >
                      <span
                        class="bold"
                        :style="
                          $route.path !== '/admin'
                            ? 'color:white'
                            : 'color:white'
                        "
                      >
                        Food Admin
                      </span>
                    </v-flex>
                  </v-layout>
                </template>
                <v-card tile flat :elevation="0" max-width="200px">
                  <v-layout wrap justify-center py-4>
                    <v-flex pt-4 xs12 text-center>
                      <span
                        style="font-size: 16px; color: #000000"
                        class="nsbold"
                      >
                        Welcome
                      </span>
                    </v-flex>
                    <v-flex pt-2 xs12 text-center>
                      <v-icon color="#CCB4B4" size="30">
                        mdi-account-circle-outline
                      </v-icon>
                    </v-flex>
                    <v-flex xs12 text-center>
                      <span
                        style="font-size: 13px; color: #1e1b1b"
                        class="nsbold"
                      >
                        <span> {{ appUser.name }} </span>
                      </span>
                    </v-flex>
                    <v-flex xs12 pa-4>
                      <v-btn
                        small
                        block
                        dark
                        dense
                        color="#766BC0"
                        @click="appLogout"
                      >
                        log out
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 px-4>
                      <v-btn
                        small
                        block
                        dark
                        dense
                        color="#766BC0"
                        @click="changePassdialogue = true"
                      >
                        Change Password
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>
          <!-- </v-toolbar> -->
          <!-- </v-app-bar> -->
        </v-flex>
      </v-layout>

      <!-- <v-flex xs12>
      <v-layout
        wrap
        class="pa-6 bg"
        style="font-family: 'Nunito Sans', sans-serif"
      >
        <v-flex xs12>
          <v-layout wrap>
            <v-flex xs7 pl-3 full-width white--text>
              <p class="nsbold my-0" style="font-size: 25px; font-weight: 800">
                Food Stock Management
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex
              align-self-center
              v-for="card in card"
              :key="card.name"
              pa-1
              pl-3
              pl-xl-0
              class="text-left"
              style="font-family: 'Nunito Sans', sans-serif"
            >
              <v-layout justify-center wrap>
                <v-flex align-self-center px-xl-12 py-xl-4 >
                  <v-card
                    :to="card.route"
                    elevation="2"
                    color="white"
                    hover
                    :ripple="false"
                    max-height="200"
                    :max-width="
                      $vuetify.breakpoint.name == 'xl' ? '100%' : '152'
                    "
                  >
                    <v-card-text
                      style="overflow-y: auto; height: 180px; width: 170px"
                    >
                      <v-img width="40px" :src="getImageURL(card.src)"></v-img>
                      <v-flex xs12 pt-2>
                        <span class="title1 nsbold" style="font-size:16px;">{{ card.name.slice(0, 50) }}</span>
                      </v-flex>
                      <v-flex xs12 pt-5>
                        <span class="text1 nsregular">{{ card.role }}</span>
                      </v-flex>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex> -->
    </v-layout>
    <v-dialog width="400px" v-model="changePassdialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Change Password </v-toolbar-title>
        </v-toolbar>

        <v-layout wrap v-if="currentPHN">
          <v-flex xs12 px-4 py-2>
            <v-text-field
              v-model="currentPHN"
              outlined
              dense
              label="Phone"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs12 px-4 pb-2>
            <v-text-field
              v-model="oldpass"
              outlined
              @change="checkPass()"
              dense
              label="Enter current password"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 px-4 pb-2>
            <v-text-field
              :disabled="res == false ? true : false"
              v-model="newpass"
              outlined
              dense
              label="Enter new password"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="changePassdialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click="changePass()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="1000"
      :value="true"
      centered
      color="red"
      v-model="snackbar"
    >
      <span style="font-size: 20px"> {{ msg }}</span>
    </v-snackbar>
  </div>
</template>
<script>
import store from "./../store";
import axios from "axios";
export default {
  data() {
    return {
      nav: false,
      //password items
      oldpass: null,
      newpass: null,
      snackbar: false,
      msg: null,
      res: false,
      changePassdialogue: false,
      //
      user: {},
      items: [
        {
          title: "Home",
          src: require("../assets/images/5.png"),
          route: "/admin",
        },
        {
          title: "Add Products",
          src: require("../assets/images/3.png"),
          route: "/addproduct",
        },
        {
          title: "Supplier",
          src: require("../assets/images/1.png"),
          route: "/supplier",
        },
        {
          title: "Purchase",
          src: require("../assets/images/7.png"),
          route: "/purchase",
        },
        {
          title: "Menu",
          src: require("../assets/images/6.png"),
          route: "/menu",
        },
        {
          title: "Analysis",
          src: require("../assets/images/4.png"),
          route: "/analysis",
        },
        {
          title: "Purchase History",
          src: require("../assets/images/8.png"),
          route: "/stock",
        },
      ],
      card: [
        {
          src: "analytic.png",
          name: "Add Products",
          role: "school performance",
          route: { name: "student" },
        },
        {
          src: "student.png",
          name: "Supplier",
          role: "View/Add Student Profile",
          route: { name: "student" },
        },
        {
          src: "staff.png",
          name: "Purchase",
          role: "Teaching & Non Teaching Staff",
          route: { name: "student" },
        },
        {
          src: "noticeboard.png",
          name: "Analysis",
          role: "2 Latest Notices",
          route: { name: "student" },
        },
        {
          src: "clsndtchr.png",
          name: "Menu",
          role: "Add / View",
          route: { name: "student" },
        },
        // {
        //   src: "subjectlogo.svg",
        //   name: "Food",
        //   role: "Add / View",
        //   route: { name: "student" },
        // },
        // {
        //   src: "exmlogo.svg",
        //   name: "Exam",
        //   role: "Add / View",
        //   flex: 3,
        //   route: { name: "student" },
        // },
      ],
    };
  },
  computed: {
    appLogin() {
      return store.state.userData;
    },
    appUser() {
      return store.state.userData;
    },
    currentPHN() {
      return store.state.userData.phone;
    },
    curRole() {
      return store.state.userData.role;
    },
  },
  methods: {
    getImageURL(src) {
      let images = require.context("../assets/images/");
      return images("./" + src);
    },
    checkPass() {
      axios({
        method: "post",
        url: "/check/oldpassword",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          phone: this.currentPHN,
          oldpassword: this.oldpass,
        },
      })
        .then((response) => {
          // this.changePassdialogue = false;
          // this.appLoading = false;
          if (response.data.status == true) {
            this.res = response.data.status;
            //  this.snackbar = true;
            // this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    changePass() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/change/password",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          phone: this.currentPHN,
          newPassword: this.newpass,
        },
      })
        .then((response) => {
          this.changePassdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.res = response.data.data;
            // this.currentPHN = null,
            (this.oldpass = null),
              (this.newpass = null),
              (this.snackbar = true);
            this.msg = response.data.msg;
            setTimeout(() => this.appLogout(), 2000);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    appLogout() {
      axios({
        method: "post",
        url: "/admin/logout",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.status) {
          store.state.userType = null;
          store.state.isLoggedIn = false;
          store.state.userData = {};
          store.state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      });
    },
  },
};
</script>
<style lang="css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700;800&display=swap");
.bg {
  background: url("../assets/images/Asset2.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}
.btn {
  text-transform: unset !important;
}
.title1 {
  color: black;
  size: 10px;
}
.text1 {
  color: #606060;
}
.fbook {
  font-size: 15px;
  font-weight: 400px;
}
a {
  color: black;
  text-decoration: none;
}

.router-link-exact-active a {
  color: black;
}
.change {
  background-color: red;
}
</style>
