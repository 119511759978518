<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout
          wrap
          justify-center
          class="pa-6 bg"
          style="font-family: 'Nunito Sans', sans-serif"
        >
          <v-flex xs12>
            <v-layout wrap>
             
              <v-flex xs7 pl-3 full-width white--text>
                <p style="font-size: 25px; font-weight: 800" class="my-0">
                  Laundry Dashboard
                </p>
                <!-- <p style="font-size: 18px; font-weight: 400">
                  Chavara Public School
                </p> -->
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 xl12  >
            <v-layout wrap justify-center>
              <v-flex
              
                xs6 md2 sm3
                lg2
                align-self-center
                v-for="card in card"
                :key="card.name"
                pa-2
                pa-sm-3
                pa-lg-4
                pa-xl-2
                class="text-left"
                style="font-family: 'Nunito Sans', sans-serif"
              >
                <v-layout justify-center wrap>
                  <v-flex align-self-center px-xl-5 py-xl-4>
                    <v-card
                      :to="card.route"
                      elevation="2"
                      color="white"
                      hover
                      :ripple="false"
                      :height=" $vuetify.breakpoint.name == 'xs' ?'190px':'190px'"
                      :width="
                        $vuetify.breakpoint.name == 'xl' ? '100%' : $vuetify.breakpoint.name == 'lg' ? '100%' : '152'
                      "
                    >
                      <!-- <v-card-text
                        style="overflow-y: auto; height: 180px; width: 170px"
                      > -->
                      <v-layout wrap  pa-2 pa-md-4 >
                        <v-flex xs12 align-self-start>
                           <v-img
                          width="40px"
                          contain
                          :src="getImageURL(card.src)"
                        ></v-img>
                        </v-flex>
                       
                        <v-flex align-self-start xs12 pt-2>
                          <span class="title1 nsbold">{{
                            card.name.slice(0, 50)
                          }}</span>
                        </v-flex>
                        <v-flex align-self-start text-left xs12 pt-2 pt-sm-5>
                          <span class="text1" :style="$vuetify.breakpoint.name == 'xs'?'font-size:12px':'' ">{{ card.role }}</span>
                        </v-flex>
                        
                      </v-layout>
                      <!-- </v-card-text> -->
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

import store from "./../store";
// import axios from "axios";
export default {
  data() {
    return {
		user: {},
      card: [
        {
          src: "student.png",
          name: "MapItem",
          role: "Map clothes to students",
          route: { name: "mapItem" },
        },
        {
          src: "analytic.png",
          name: "Item In",
          role: "Add clothes for washing",
          route: { name: "inItem" },
        },
         {
          src: "clsndtchr.png",
          name: "Item Out",
          role: "Remove cloth from washing",
          route: { name: "outItem" },
        },
        {
          src: "staff.png",
          name: "Student List",
          role: "Student-wise laundry list",
          route: { name: "viewstudent" },
        },
        {
          src: "noticeboard.png",
          name: "Laundry Status",
          role: "Current and pending washing status",
          route: { name: "clothStatus" },
        },
       
        {
          src: "exmlogo.svg",
          name: "Report",
          role: "Current and pending washing status",
          route: { name: "laundryhistory" },
        },
        
      ],
    };
  },
  computed: {
    appLogin() {
      return store.state.email;
    },
    appUser() {
      return store.state.userData;
    },
  },
  methods: {
    getImageURL(src) {
      let images = require.context("../assets/images/");
      return images("./" + src);
    },
   
  },
};
</script>
<style lang="css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700;800&display=swap");
.bg {
  background: url("../assets/images/Asset2.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}
.btn {
  text-transform: unset !important;
}

a {
  color: black;
  text-decoration: none;
}

.router-link-exact-active a {
  color: black;
}
</style>