<template>
<div>
  <v-card elevation="2" tile>
    <!-- <h1>{{this.$route.path }}</h1> -->
    <!-- <v-layout wrap justify-start style="background-color:#F5F5F5"> -->
    <v-layout
      wrap
      justify-start
      :style="
        $route.path == '/HostelManager'
          ? ' background-color: #ffffff '
          : ' background-color: #766BC0 '
      "
    >
      <v-flex
        xs12
        py-2
        :style="$route.path=='/HostelManager'?' color: black ': ' color: white '"
      >
        <!-- <v-app-bar > -->
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        <!-- <v-toolbar flat fixed color="#FFFFFF" prominent height="52px"> -->
        <v-layout wrap justify-space-around hidden-md-and-down>
          <!-- <v-flex xs1 lg1> -->
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
          <!-- </v-flex> -->

          <v-flex md1>
            <v-img
              height="50px"
              class="mx-auto"
              contain
              src="../assets/images/logo.png"
            >
            </v-img>
          </v-flex>
          <v-flex
            align-self-center
            lg2
            class="text-left nsbold"
            :style="
              $route.path == '/HostelManager' ? 'color:#000000' : 'color:#ffffff'
            "
          >
            St.Vincent Boarding House 
          </v-flex>

          <v-flex xs12 md7 lg8 text-center pt-2 px-2>
            <!--------------------------------------------------->
            <!-- <template>
  <v-sheet 
   :style="
        $route.path == '/HostelManager'
          ? ' background-color: #ffffff '
          : ' background-color: #766BC0 '
      "
    
  >
    <v-slide-group
      multiple
     
      show-arrows
     dark
    >
      <v-slide-item
        v-for="(item, i) in outpassItems"
                  :key="i"
        
      >
        <v-btn
          :to="item.route"
           text
            :style="
                        $route.path == '/HostelManager' ? 'color:#000000' : 'color:#ffffff'
                      "
           dark
          depressed
          rounded
         
        >
          {{ item.title }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
   </template> -->
            <!----------------------------------------------------->
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center px-1>
                <v-layout wrap>
                  <v-flex
                    
                    align-self-center
                    v-for="(item, i) in outpassItems"
                    :key="i"
                  >
                    <div v-if="!item.dropdown">
                      <v-btn
                        :style="
                          $route.name == item.name
                            ? 'border-bottom:2px solid white;padding-bottom:6px'
                            : ''
                        "
                        class="btn nsbold"
                        @click="$router.push({ path: item.route })"
                        text
                        tile
                          >
                           <!-- style="item.title=='home'? 'background-color:red'" -->
                        <span
                          :style="
                            $route.path == '/HostelManager'
                              ? 'color:#000000'
                              : 'color:#ffffff'
                          "
                        >
                          {{ item.title }}
                        </span>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-menu open-on-hover bottom tile offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :style="
                              $route.name == item.name
                                ? 'border-bottom:2px solid white;padding-bottom:6px'
                                : ''
                            "
                            class="btn nsbold"
                            text
                            tile
                          >
                            <span
                              :style="
                                $route.path == '/HostelManager'
                                  ? 'color:#000000'
                                  : 'color:#ffffff'
                              "
                            >
                              {{ item.title }}<v-icon x-small style="color:white;">mdi-chevron-down</v-icon>
                            </span>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            v-for="(item2, i2) in item.dropdown"
                            :key="i2"
                          >
                            <v-list-item-title>
                              <v-btn
                                class="nsbold"
                                @click="$router.push({ path: item2.route })"
                                text
                                style="text-transform: none"
                                tile
                              >
                                <span>
                                  {{ item2.title }} 
                                  <!--  -->
                                </span>
                              </v-btn>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 md2 lg1 px-4 text-right>
            <v-menu offset-y left>
              <template v-slot:activator="{ attrs, on }">
                <v-layout wrap justify-center v-bind="attrs" v-on="on">
                  <v-flex xs12 text-center align-self-center>
                    <v-icon
                      size="25"
                      title="Account"
                      :style="
                        $route.path == '/HostelManager'
                          ? 'color:#000000'
                          : 'color:white'
                      "
                    >
                      mdi-account
                    </v-icon>
                  </v-flex>
                  <v-flex 
                    class="hidden-xs-only"
                    xs12
                    text-center
                    align-self-center
                    style="line-height: 1"
                    
                  >
                    <span
                      class="bold"
                      :style="
                        $route.path == '/HostelManager'
                          ? 'color:#000000'
                          : 'color:white'
                      "
                    >
                      <!-- {{ appUser.name }} -->
                      Hostel 
                      <br/>
                      Manager
                    </span>
                  </v-flex>
                </v-layout>
              </template>
              <v-card tile flat :elevation="0" max-width="200px">
                <v-layout wrap justify-center py-4>
                  <v-flex pt-4 xs12 text-center>
                    <span
                      style="font-size: 16px; color: #000000"
                      class="nsbold"
                    >
                      Welcome
                    </span>
                  </v-flex>
                  <v-flex pt-2 xs12 text-center>
                    <v-icon color="#CCB4B4" size="30">
                      mdi-account-circle-outline
                    </v-icon>
                  </v-flex>
                  <v-flex xs12 text-center>
                    <span
                      style="font-size: 13px; color: #1e1b1b"
                      class="nsbold"
                    >
                      <span> {{ appUser.name }} </span>
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-2 px-2>
                    <v-btn small block dark dense color="#766BC0" @click="appLogout">
                      log out
                    </v-btn>
                  </v-flex>
                     <v-flex xs12 pa-2>
                    <v-btn  small block dark dense color="#766BC0" @click="changePassdialogue = true">
                      Change Password
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>
        <!----------------------xs header----------------------->
        <v-layout wrap class="hidden-lg-and-up">
          <v-flex xs1 align-self-center>
            <v-app-bar-nav-icon
              color="white"
              :style="
                $route.path != '/HostelManager'
                  ? ' background-color: #766BC0 '
                  : ' background-color: #766BC0 '
              "
              @click="nav = true"
            ></v-app-bar-nav-icon>
            <v-navigation-drawer
              height="450px"
              absolute
              temporary
              v-model="nav"
            >
              <v-layout class="menufont1" wrap justify-center pa-3>
                <v-flex xs12>
                  <v-list dense nav>
                    <v-list-item
                      v-for="item in outpassItems"
                      :key="item.title"
                      link
                      :to="item.route"
                    >
                      <v-list-item-icon>
                        <v-img :src="item.src"></v-img>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-navigation-drawer>
          </v-flex>
          <v-flex
            xs6
            sm8
            class="text-left pl-2 pt-1 nsbold hidden-xs-only"
            style="font-size: 18px; font-weight: 500; color: white"
            >St.Vincent Boarding House
          </v-flex>
          <!-- for xs header -->
          <v-flex
            xs6
            sm8
            class="text-left pl-2 pt-1 nsbold hidden-sm-and-up"
            style="font-size: 18px; font-weight: 500; color: white"
            >SVBH
          </v-flex>
          <!----------------->
          <v-flex xs3 sm2 px-4>
            <v-btn class="nsbold white--text" text @click="appLogout()">
              <span :style="'color:white'"> LOGOUT </span>
            </v-btn>
            
          </v-flex>
        </v-layout>
        <!------------------------------------------------------>
        <!-- </v-toolbar> -->
        <!-- </v-app-bar> -->
        
      </v-flex>
    </v-layout>
  </v-card>
   <v-dialog width="400px" v-model="changePassdialogue">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Change Password </v-toolbar-title>
              </v-toolbar>

              <v-layout wrap v-if="currentPHN">
                <v-flex xs12 px-4 py-2>
                  <v-text-field
                    v-model="currentPHN"
                    outlined
                    dense
                    label="Phone"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 pb-2>
                  <v-text-field
                    v-model="oldpass"
                    outlined
                    @change="checkPass()"
                    dense
                    label="Enter current password"
                  ></v-text-field>
                </v-flex>
               
                 <v-flex xs12 px-4 pb-2 
                  
                 >
                  <v-text-field
                  :disabled="res == false ? true : false"
                    v-model="newpass"
                    outlined
                    dense
                    label="Enter new password"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="changePassdialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="changePass()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
           <v-snackbar
            :timeout="1000"
            :value="true"
            centered
            color="red"
            v-model="snackbar"
          >
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-snackbar>
          </div>
</template>
<script>
import store from "../store";
import axios from "axios";
export default {
  data() {
    return {
      nav: true,
      user: {},
      // currentPHN: null,
      //password items
      oldpass: null,
      newpass: null,
      snackbar: false,
      msg: null,
      res: false,
      changePassdialogue: false,
      //
      outpassItems: [
        {
          title: "Home",
          src: require("../assets/images/add.png"),
          route: "/HostelManager",
          name: "HostelManager",
        },
        {
          title: "Outpass",
          src: require("../assets/images/add.png"),
          route: "/generateOutpass",
          name: "generateOutpass",
          dropdown: [
            {
              title: "New Outpass",
              src: require("../assets/images/add.png"),
              route: "/generateOutpass",
              name: "generateOutpass",
            },
            {
              title: "Outpass Report",
              src: require("../assets/images/purchase.png"),
              route: "/outpassReport",
              name: "outpassReport",
            },
          ],
        },
        // {
        //   title: "Outpass Report",
        //   src: require("../assets/images/purchase.png"),
        //   route: "/outpassReport",
        //   name: "outpassReport",
        // },
        {
          title: "Students",
          src: require("../assets/images/report.png"),
          route: "/outpassStudentList",
          name: "outpassStudentList",
        },
        {
          title: "Attendance",
          src: require("../assets/images/report.png"),
          route: "/attendanceReport",
          name: "attendanceReport",
          dropdown: [
            {
              title: "Attendance Report",
              src: require("../assets/images/add.png"),
              route: "/attendanceReport",
              name: "attendanceReport",
            },
            {
              title: "Leave Request",
              src: require("../assets/images/report.png"),
              route: "/HMleaveRequest",
              name: "HMleaveRequest",
            },
            {
              title: "Extend Leave",
              src: require("../assets/images/report.png"),
              route: "/HMleaveExtentionApproval",
              name: "HMleaveExtentionApproval",
            },
            {
              title: "Leave Report",
              src: require("../assets/images/report.png"),
              route: "/leaveReport",
              name: "leaveReport",
            },
          ],
        },
        // {
        //   title: "Leave Request",
        //   src: require("../assets/images/report.png"),
        //   route: "/leaveRequest",
        //   name: "leaveRequest",
        // },
        // {
        //   title: "Extend Leave",
        //   src: require("../assets/images/report.png"),
        //   route: "/leaveExtentionApproval",
        //   name: "leaveExtentionApproval",
        // },
        {
          title: "Visitor",
          src: require("../assets/images/report.png"),
          route: "/visitorReport",
          name: "visitorReport",
        },
        {
          title: "Room",
          src: require("../assets/images/report.png"),
          route: "/addFloor",
          name: "addFloor",
          dropdown: [
            {
              title: "Add Floor",
              src: require("../assets/images/add.png"),
              route: "/addFloor",
              name: "addFloor",
            },
            {
              title: "Add Room",
              src: require("../assets/images/report.png"),
              route: "/addRoom",
              name: "addRoom",
            },
                {
              title: "Room Mapping",
              src: require("../assets/images/report.png"),
              route: "/roomStrctr",
              name: "roomStrctr",
            },
          ],
        },
        {
          title: "More",
          src: require("../assets/images/report.png"),
          route: "/addFloor",
          name: "addFloor",
          dropdown: [
            {
              title: "Study Permission",
              src: require("../assets/images/add.png"),
              route: "/studyRequest",
              name: "studyRequest",
            },
            {
              title: "Study Permission Report",
              src: require("../assets/images/report.png"),
              route: "/permissionReport",
              name: "permissionReport",
            },
             {
              title: "Incidental Reports",
              src: require("../assets/images/report.png"),
              route: "/incidentalReport",
              name: "incidentalReport",
            },
             {
              title: "Apology",
              src: require("../assets/images/report.png"),
              route: "/apologyReport",
              name: "apologyReport",
            },
            //     {
            //   title: "Room Allotment",
            //   src: require("../assets/images/report.png"),
            //   route: "/roomMapping",
            //   name: "roomMapping",
            // },
          ],
        },
      ],
    };
  },
  computed: {
    appLogin() {
      return store.state.userData;
    },
    appUser() {
      return store.state.userData;
    },
   currentPHN()
   {
     return store.state.userData.phone;
   },
   curRole()
   {
     return store.state.userData.role;
   }

  },
  methods: {
    getImageURL(src) {
      let images = require.context("../assets/images/");
      return images("./" + src);
    },
    checkPass(){
      axios({
        method: "post",
        url: "/check/oldpassword",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          phone: this.currentPHN,
          oldpassword: this.oldpass,
        },
      })
        .then((response) => {
          // this.changePassdialogue = false;
          // this.appLoading = false;
          if (response.data.status == true) {
            this.res = response.data.status;
            //  this.snackbar = true;
            // this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    changePass(){
      this.appLoading = true;
      axios({
        method: "post",
        url: "/change/password",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          phone: this.currentPHN,
          newPassword: this.newpass,
        },
      })
        .then((response) => {
          this.changePassdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.res = response.data.data;
             this.oldpass = null,
            this.newpass = null,  
             this.snackbar = true;
            this.msg = response.data.msg;
            setTimeout(() => this.appLogout(),2000)
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    appLogout() {
      axios({
        method: "post",
        url: "/admin/logout",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        localStorage.removeItem("token");
        if (response.data.status) {
          store.state.userType = null;
          store.state.isLoggedIn = false;
          store.state.userData = {};
          store.state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700;800&display=swap");
.bgblue {
  background-color: #766bc0 !important;
}
.bggrey {
  background-color: #312e2e !important;
}
.bg {
  background: url("../assets/images/Asset2.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}
.btn {
  text-transform: unset !important;
}

a {
  color: black;
  text-decoration: none;
}

.router-link-exact-active a {
  color: black;
}
.changee {
  font-size: 30px !important;
}
</style>
