// import { from } from "core-js/core/array";
import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "login",
      props: true,
      component: () => import("./components/loginnew"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/privacypolicy",
      name: "privacypolicy",
      props: true,
      component: () => import("./components/PrivacyPolicy"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/test",
      name: "test",
      props: true,
      component: () => import("./components/test"),
      meta: {
        requiresAuth: false,
      },
    },

    //foodstockadmin
    {
      path: "/admin",
      name: "admin",
      props: true,
      component: () => import("./views/admin"),
      // meta: {
      //   requiresAuth: true,
      //   isSuperAdmin: false,
      //   isLaundryAdmin: false,
      //   isFooodAdmin: true,
      //   isAssetAdmin: false,
      // },

      children: [
        {
          path: "",
          name: "foodHome",
          props: true,
          component: () => import("./views/food/foodHome"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
        {
          path: "/addproduct",
          name: "addproduct",
          props: true,
          component: () => import("./views/food/addProduct"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
        {
          path: "/supplier",
          name: "supplier",
          props: true,
          component: () => import("./views/food/supplier"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
        {
          path: "/purchase",
          name: "purchase",
          props: true,
          component: () => import("./views/food/purchase"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
        // {
        //   path: "/purchase",
        //   name: "purchase",
        //   props: true,
        //   component: () => import("./views/food/purchaseOld"),
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        {
          path: "/analysis",
          name: "analysis",
          props: true,
          component: () => import("./views/food/analysis"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
        {
          path: "/menu",
          name: "menu",
          props: true,
          component: () => import("./views/food/menu"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },

        // {
        //   path: "/menu",
        //   name: "menu",
        //   props: true,
        //   component: () => import("./backup/menu"),
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },

        {
          path: "/stock",
          name: "stock",
          props: true,
          component: () => import("./views/food/stock"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
        {
          path: "/shopHistory",
          name: "shopHistory",
          props: true,
          component: () => import("./views/food/shopHistory"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isLaundryAdmin: false,
            isFooodAdmin: true,
            isAssetAdmin: false,
            isHostelWarden: false,
            isHostelManager: false,
            isvicePrincipal: false,
          },
        },
      ],
    },

    //asset

    {
      path: "/assetAdmin",
      name: "assetAdmin",
      props: true,
      component: () => import("./views/admin"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: true,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },

      children: [
        {
          path: "",
          name: "assetAdmin",
          props: true,
          component: () => import("./views/assets/assetHome"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/purchaseAsset",
          name: "purchaseAsset",
          props: true,
          component: () => import("./views/assets/purchaseAsset"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/addAsset",
          name: "addAsset",
          props: true,
          component: () => import("./views/assets/addAsset"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/assetHistory",
          name: "assetHistory",
          props: true,
          component: () => import("./views/assets/assetHistory"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/assetReport",
          name: "assetReport",
          props: true,
          component: () => import("./views/assets/assetReport"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    //superadmin
    {
      path: "/addAdmin",
      name: "addAdmin",
      props: true,
      component: () => import("./views/SuperAdmin/addAdmin"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/student",
      name: "student",
      props: true,
      component: () => import("./views/SuperAdmin/studentList"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/addHostel",
      name: "/addHostel",
      props: true,
      component: () => import("./views/SuperAdmin/addHostel"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/profile",
      name: "profile",
      props: true,
      component: () => import("./views/SuperAdmin/profile"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/newstudent",
      name: "newstudent",
      props: true,
      component: () => import("./views/SuperAdmin/newstudent"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/feedback",
      name: "feedback",
      props: true,
      component: () => import("./views/SuperAdmin/feedback"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/feedbackReport",
      name: "feedbackReport",
      props: true,
      component: () => import("./views/SuperAdmin/feedbackReport"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adLaundryHistory",
      name: "adLaundryHistory",
      props: true,
      component: () => import("./views/SuperAdmin/adLaundryHistory"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adminAsset",
      name: "adminAsset",
      props: true,
      component: () => import("./views/SuperAdmin/adminAsset"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adminFood",
      name: "adminFood",
      props: true,
      component: () => import("./views/SuperAdmin/adminFood"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adminHostel",
      name: "adminHostel",
      props: true,
      component: () => import("./views/SuperAdmin/adminHostel"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adminShopHistory",
      name: "adminShopHistory",
      props: true,
      component: () => import("./views/SuperAdmin/adminShopHistory"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    //superadminatendancetab
    {
      path: "/adAttenList",
      name: "adAttenList",
      props: true,
      component: () => import("./views/SuperAdmin/adAttendance/adAttenList"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adAbscList",
      name: "adAbscList",
      props: true,
      component: () => import("./views/SuperAdmin/adAttendance/adAbscList"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adLeaveList",
      name: "adLeaveList",
      props: true,
      component: () => import("./views/SuperAdmin/adAttendance/adLeaveList"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/adOutpList",
      name: "adOutpList",
      props: true,
      component: () => import("./views/SuperAdmin/adAttendance/adOutpList"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: true,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
   
    //laundry

    {
      path: "/inItem",
      name: "inItem",
      props: true,
      component: () => import("./views/laundry/inItem"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/outItem",
      name: "outItem",
      props: true,
      component: () => import("./views/laundry/outItem"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },

    {
      path: "/viewstudent",
      name: "viewstudent",
      props: true,
      component: () => import("./views/laundry/viewstudent"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/studentclothes",
      name: "studentclothes",
      props: true,
      component: () => import("./views/laundry/stcloth"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/clothStatus",
      name: "clothStatus",
      props: true,
      component: () => import("./views/laundry/clothStatus"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/mapItem",
      name: "mapItem",
      props: true,
      component: () => import("./views/laundry/mapItem"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/laundryReport",
      name: "laundryReport",
      props: true,
      component: () => import("./views/laundry/laundryReport"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/report",
      name: "report",
      props: true,
      component: () => import("./views/laundry/report"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/laundryhistory",
      name: "laundryhistory",
      props: true,
      component: () => import("./views/laundry/laundryhistory"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/categories",
      name: "categories",
      props: true,
      component: () => import("./views/laundry/categories"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    {
      path: "/clothHistory",
      name: "clothHistory",
      props: true,
      component: () => import("./views/laundry/clothHistory"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: true,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: false,
      },
    },
    // {
    //   path: "/deletecloth",
    //   name:"deletecloth",
    //   props: true,
    //   component: () => import("./views/laundry/deletecloth"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },

    //   {
    //     path: "*",
    //     component: () => import("./components/ErrorPage"),

    // },

    //outpass
    {
      path: "/HostelWarden",
      name: "HostelWarden",
      props: true,
      component: () => import("./views/outpass/outpassHome"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: true,
        isHostelManager: true,
        isvicePrincipal: false,
      },

      children: [
        {
          path: "",
          name: "HostelWarden",
          props: true,
          component: () => import("./views/outpass/outpassCount"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/generateOutpass",
          name: "generateOutpass",
          props: true,
          component: () => import("./views/outpass/generateOutpass"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/outpassReport",
          name: "outpassReport",
          props: true,
          component: () => import("./views/outpass/outpassReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/outpassStudentList",
          name: "outpassStudentList",
          props: true,
          component: () => import("./views/outpass/outpassStudentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/studentOutpass",
          name: "studentOutpass",
          props: true,
          component: () => import("./views/outpass/studentOutpass"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/attendanceReport",
          name: "attendanceReport",
          props: true,
          component: () => import("./views/outpass/attendanceReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/attendanceHistoryEdit",
          name: "attendanceHistoryEdit",
          props: true,
          component: () => import("./views/outpass/attendanceHistoryEdit"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/staffAttandance",
          name: "staffAttandance",
          props: true,
          component: () => import("./views/outpass/staffAttandance"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/wardenAttendanceReport",
          name: "wardenAttendanceReport",
          props: true,
          component: () => import("./views/outpass/wardenAttendanceReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/staffAttendanceGroup",
          name: "staffAttendanceGroup",
          props: true,
          component: () => import("./views/outpass/staffAttendanceGroup"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/staffAttandanceReport",
          name: "staffAttandanceReport",
          props: true,
          component: () => import("./views/outpass/staffAttandanceReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/attendanceList",
          name: "attendanceList",
          props: true,
          component: () => import("./views/outpass/presentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/absentList",
          name: "absentList",
          props: true,
          component: () => import("./views/outpass/absentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveList",
          name: "leaveList",
          props: true,
          component: () => import("./views/outpass/leaveList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/outpassList",
          name: "outpassList",
          props: true,
          component: () => import("./views/outpass/outpassList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveRequest",
          name: "leaveRequest",
          props: true,
          component: () => import("./views/outpass/leaveRequest"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveExtentionApproval",
          name: "leaveExtentionApproval",
          props: true,
          component: () => import("./views/outpass/leaveExtentionApproval"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveReport",
          name: "leaveReport",
          props: true,
          component: () => import("./views/outpass/leaveReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/studyRequest",
          name: "studyRequest",
          props: true,
          component: () => import("./views/outpass/studyRequest"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/visitorReport",
          name: "visitorReport",
          props: true,
          component: () => import("./views/outpass/visitorReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/incidentalReport",
          name: "incidentalReport",
          props: true,
          component: () => import("./views/outpass/incidentalReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/apologyReport",
          name: "apologyReport",
          props: true,
          component: () => import("./views/outpass/apologyReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/todayPresent",
          name: "todayPresent",
          props: true,
          component: () => import("./views/outpass/todayPresent"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/todayPresentList",
          name: "todayPresentList",
          props: true,
          component: () => import("./views/outpass/todayPresentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/todayAbsentList",
          name: "todayAbsentList",
          props: true,
          component: () => import("./views/outpass/todayAbsentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/todayAbsent",
          name: "todayAbsent",
          props: true,
          component: () => import("./views/outpass/todayAbsent"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/permissionReport",
          name: "permissionReport",
          props: true,
          component: () => import("./views/outpass/permissionReport"),
          meta: {
            requiresAuth: true,
          },
        },
        
        //hostel mapping
        {
          path: "/hostelMapping",
          name: "hostelMapping",
          props: true,
          component: () => import("./views/roomManagement/hostelMapping"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/unMappedStList",
          name: "unMappedStList",
          props: true,
          component: () => import("./views/roomManagement/unMappedStList"),
          meta: {
            requiresAuth: true,
          },
        },
        //Room Mannagement
        {
          path: "/addRoom",
          name: "addRoom",
          props: true,
          component: () => import("./views/roomManagement/addRoom"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/addFloor",
          name: "addFloor",
          props: true,
          component: () => import("./views/roomManagement/addFloor"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/roomMapping",
          name: "roomMapping",
          props: true,
          component: () => import("./views/roomManagement/roomMapping"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/roomStrctr",
          name: "roomStrctr",
          props: true,
          component: () => import("./views/roomManagement/roomStrctr"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/hostelStList",
          name: "hostelStList",
          props: true,
          component: () => import("./views/roomManagement/hostelStList"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    // Hostel Manager
    {
      path: "/HostelManager",
      // name: "HostelManager",
      props: true,
      component: () => import("./views/outpass/outpassHome"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: true,
        isvicePrincipal: false,
      },

      children: [
        {
          path: "",
          name: "HostelWarden",
          props: true,
          component: () => import("./views/outpass/HMoutpassCount"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/generateOutpass",
          name: "generateOutpass",
          props: true,
          component: () => import("./views/outpass/generateOutpass"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/outpassReport",
          name: "outpassReport",
          props: true,
          component: () => import("./views/outpass/outpassReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/outpassStudentList",
          name: "outpassStudentList",
          props: true,
          component: () => import("./views/outpass/outpassStudentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/studentOutpass",
          name: "studentOutpass",
          props: true,
          component: () => import("./views/outpass/studentOutpass"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/attendanceReport",
          name: "attendanceReport",
          props: true,
          component: () => import("./views/outpass/attendanceReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/attendanceList",
          name: "attendanceList",
          props: true,
          component: () => import("./views/outpass/presentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/absentList",
          name: "absentList",
          props: true,
          component: () => import("./views/outpass/absentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveList",
          name: "leaveList",
          props: true,
          component: () => import("./views/outpass/leaveList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/outpassList",
          name: "outpassList",
          props: true,
          component: () => import("./views/outpass/outpassList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/HMleaveRequest",
          name: "HMleaveRequest",
          props: true,
          component: () => import("./views/outpass/HMleaveRequest"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveExtentionApproval",
          name: "leaveExtentionApproval",
          props: true,
          component: () => import("./views/outpass/leaveExtentionApproval"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/HMleaveExtentionApproval",
          name: "HMleaveExtentionApproval",
          props: true,
          component: () => import("./views/outpass/HMleaveExtentionApproval"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveReport",
          name: "leaveReport",
          props: true,
          component: () => import("./views/outpass/leaveReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/studyRequest",
          name: "studyRequest",
          props: true,
          component: () => import("./views/outpass/studyRequest"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/visitorReport",
          name: "visitorReport",
          props: true,
          component: () => import("./views/outpass/visitorReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/incidentalReport",
          name: "incidentalReport",
          props: true,
          component: () => import("./views/outpass/incidentalReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/apologyReport",
          name: "apologyReport",
          props: true,
          component: () => import("./views/outpass/apologyReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/todayPresent",
          name: "todayPresent",
          props: true,
          component: () => import("./views/outpass/todayPresent"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/todayAbsent",
          name: "todayAbsent",
          props: true,
          component: () => import("./views/outpass/todayAbsent"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/permissionReport",
          name: "permissionReport",
          props: true,
          component: () => import("./views/outpass/permissionReport"),
          meta: {
            requiresAuth: true,
          },
        },

        //Room Mannagement
        {
          path: "/addRoom",
          name: "addRoom",
          props: true,
          component: () => import("./views/roomManagement/addRoom"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/addFloor",
          name: "addFloor",
          props: true,
          component: () => import("./views/roomManagement/addFloor"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/roomMapping",
          name: "roomMapping",
          props: true,
          component: () => import("./views/roomManagement/roomMapping"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/roomStrctr",
          name: "roomStrctr",
          props: true,
          component: () => import("./views/roomManagement/roomStrctr"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    //vicePrincipal
    {
      path: "/viceprincipal",
      name: "viceprincipal",
      props: true,
      component: () => import("./views/vicePrincipal/vicePrincipalHome"),
      meta: {
        requiresAuth: true,
        isSuperAdmin: false,
        isLaundryAdmin: false,
        isFooodAdmin: false,
        isAssetAdmin: false,
        isHostelWarden: false,
        isHostelManager: false,
        isvicePrincipal: true,
      },

      children: [
        {
          path: "",
          name: "viceprincipal",
          props: true,
          component: () => import("./views/vicePrincipal/approveLeave"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leaveReport1",
          name: "leaveReport1",
          props: true,
          component: () => import("./views/vicePrincipal/leaveReport"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/approvedRequests",
          name: "approvedRequests",
          props: true,
          component: () => import("./views/vicePrincipal/approvedRequests"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/vcStudentList",
          name: "vcStudentList",
          props: true,
          component: () => import("./views/vicePrincipal/vcStudentList"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/vcPerStudent",
          name: "vcPerStudent",
          props: true,
          component: () => import("./views/vicePrincipal/vcPerStudent"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/vcLeaveExtentionApproval",
          name: "vcLeaveExtentionApproval",
          props: true,
          component: () =>
            import("./views/vicePrincipal/vcLeaveExtentionApproval"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "*",
      props: true,
      component: () => import("./components/404"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/servererror",
      name: "servererror",
      props: true,
      component: () => import("./components/500"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});
router.beforeEach((to, from, next) => {
  // if (localStorage.getItem("token") && to.name == 'login') {

  //   next({ name: "admin" });
  //   return;
  // }
  // if (to.matched.some((route) => route.meta.requiresAuth == true) && !localStorage.getItem('token')) {
  //   next({ name: "login" });
  //   return;
  // }
  // next();
  //Old

  //New

  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") == "AssetAdmin"
  ) {
    next({ name: "assetAdmin" });
    return;
  }

  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") === "laundryadmin"
  ) {
    next({ name: "admin" });
    return;
  }
  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") === "foodstockadmin"
  ) {
    next({ name: "admin" });
    return;
  }
  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") === "HostelWarden"
  ) {
    next({ name: "HostelWarden" });
    return;
  }
  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") === "viceprincipal"
  ) {
    next({ name: "viceprincipal" });
    return;
  }

  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") === "Admin"
  ) {
    next({ name: "admin" });
    return;
  }

  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    !localStorage.getItem("token")
  ) {
    next({ name: "login" });
    return;
  }

  // Redirection userwise
  if (
    to.matched.some((route) => route.meta.isAssetAdmin == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "AssetAdmin"
  ) {
    next({ name: "assetAdmin" });
    return;
  }
  if (
    to.matched.some((route) => route.meta.isLaundryAdmin == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "laundryadmin"
  ) {
    next({ name: "admin" });
    return;
  }

  if (
    to.matched.some((route) => route.meta.isFooodAdmin == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "foodstockadmin"
  ) {
    next({ name: "admin" });
    return;
  }

  if (
    to.matched.some((route) => route.meta.isHostelWarden == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "HostelWarden"
  ) {
    next({ name: "HostelWarden" });
    return;
  }

  if (
    to.matched.some((route) => route.meta.isHostelManager == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "Hostel Manager"
  ) {
    next({ name: "HostelManager" });
    return;
  }
  if (
    to.matched.some((route) => route.meta.isvicePrincipal == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "viceprincipal"
  ) {
    next({ name: "viceprincipal" });
    return;
  }

  if (
    to.matched.some((route) => route.meta.isSuperAdmin == false) &&
    store.state.isLoggedIn == true &&
    store.state.userType == "Admin"
  ) {
    next({ name: "admin" });
    return;
  }

  next();
});

export default router;
