var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2","tile":""}},[_c('v-layout',{style:(_vm.$route.path == '/HostelWarden'
          ? ' background-color: #ffffff '
          : ' background-color: #766BC0 '),attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{style:(_vm.$route.path =='/HostelWarden'
          ? ' color: black '
          : ' color: white '),attrs:{"xs12":"","py-2":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-around":"","hidden-md-and-down":""}},[_c('v-flex',{staticStyle:{"cursor":"pointer"},attrs:{"md1":""},on:{"click":function($event){return _vm.$router.push('/HostelWarden')}}},[_c('v-img',{staticClass:"mx-auto",attrs:{"height":"50px","contain":"","src":require("../assets/images/logo.png")}})],1),_c('v-flex',{staticClass:"text-left nsbold",staticStyle:{"cursor":"pointer"},style:(_vm.$route.path == '/HostelWarden'
                ? 'color:#000000'
                : 'color:#ffffff'),attrs:{"align-self-center":"","lg2":""},on:{"click":function($event){return _vm.$router.push('/HostelWarden')}}},[_vm._v(" St.Vincent Boarding House ")]),_c('v-flex',{attrs:{"xs12":"","md7":"","lg7":"","text-center":"","pt-2":"","px-2":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","px-1":""}},[(_vm.appUser.usertype=='1')?_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.outpassItems),function(item,i){return _c('v-flex',{key:i,attrs:{"align-self-center":""}},[(!item.dropdown)?_c('div',[_c('v-btn',{staticClass:"btn nsbold",style:(_vm.$route.name == item.name && item.name !='HostelWarden'
                            ? 'border-bottom:2px solid white;padding-bottom:6px'
                            : ''),attrs:{"text":"","tile":""},on:{"click":function($event){return _vm.$router.push({ path: item.route })}}},[_c('span',{style:(_vm.$route.path == '/HostelWarden'
                              ? 'color:#000000'
                              : 'color:#ffffff')},[_vm._v(" "+_vm._s(item.title)+" ")])])],1):_c('div',[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn nsbold",style:(_vm.$route.name == item.name
                                ? 'border-bottom:2px solid white;padding-bottom:6px'
                                : ''),attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_c('span',{style:(_vm.$route.path == '/HostelWarden'
                                  ? 'color:#000000'
                                  : 'color:#ffffff')},[_vm._v(" "+_vm._s(item.title)),_c('v-icon',{staticStyle:{"color":"white"},attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)])]}}],null,true)},[_c('v-list',_vm._l((item.dropdown),function(item2,i2){return _c('v-list-item',{key:i2},[_c('v-list-item-title',[_c('v-btn',{staticClass:"nsbold",staticStyle:{"text-transform":"none"},attrs:{"text":"","tile":""},on:{"click":function($event){return _vm.$router.push({ path: item2.route })}}},[_c('span',[_vm._v(" "+_vm._s(item2.title)+" ")])])],1)],1)}),1)],1)],1)])}),1):_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.outpassItems3),function(item,i){return _c('v-flex',{key:i,attrs:{"align-self-center":""}},[(!item.dropdown)?_c('div',[_c('v-btn',{staticClass:"btn nsbold",style:(_vm.$route.name == item.name
                            ? 'border-bottom:2px solid white;padding-bottom:6px'
                            : ''),attrs:{"text":"","tile":""},on:{"click":function($event){return _vm.$router.push({ path: item.route })}}},[_c('span',{style:(_vm.$route.path == '/HostelWarden'
                              ? 'color:#000000'
                              : 'color:#ffffff')},[_vm._v(" "+_vm._s(item.title)+" ")])])],1):_c('div',[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn nsbold",style:(_vm.$route.name == item.name
                                ? 'border-bottom:2px solid white;padding-bottom:6px'
                                : ''),attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_c('span',{style:(_vm.$route.path == '/HostelWarden'
                                  ? 'color:#000000'
                                  : 'color:#ffffff')},[_vm._v(" "+_vm._s(item.title)),_c('v-icon',{staticStyle:{"color":"white"},attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)])]}}],null,true)},[_c('v-list',_vm._l((item.dropdown),function(item2,i2){return _c('v-list-item',{key:i2},[_c('v-list-item-title',[_c('v-btn',{staticClass:"nsbold",staticStyle:{"text-transform":"none"},attrs:{"text":"","tile":""},on:{"click":function($event){return _vm.$router.push({ path: item2.route })}}},[_c('span',[_vm._v(" "+_vm._s(item2.title)+" ")])])],1)],1)}),1)],1)],1)])}),1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","md2":"","lg2":"","px-4":"","text-right":""}},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var attrs = ref.attrs;
                                  var on = ref.on;
return [_c('v-layout',_vm._g(_vm._b({attrs:{"wrap":"","justify-center":""}},'v-layout',attrs,false),on),[_c('v-flex',{attrs:{"xs12":"","text-center":"","align-self-center":""}},[_c('v-icon',{style:(_vm.$route.path == '/HostelWarden'
                          ? 'color:#000000'
                          : 'color:white'),attrs:{"size":"25","title":"Account"}},[_vm._v(" mdi-account ")])],1),_c('v-flex',{staticClass:"hidden-xs-only",staticStyle:{"line-height":"0.5"},attrs:{"xs12":"","text-center":"","align-self-center":"","pt-1":""}},[_c('span',{staticClass:"bold",style:(_vm.$route.path == '/HostelWarden'
                          ? 'color:#000000'
                          : 'color:white')},[_vm._v(" Warden ")])])],1)]}}])},[_c('v-card',{attrs:{"tile":"","flat":"","elevation":0,"max-width":"200px"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-4":""}},[_c('v-flex',{attrs:{"pt-4":"","xs12":"","text-center":""}},[_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"16px","color":"#000000"}},[_vm._v(" Welcome ")])]),_c('v-flex',{attrs:{"pt-2":"","xs12":"","text-center":""}},[_c('v-icon',{attrs:{"color":"#CCB4B4","size":"30"}},[_vm._v(" mdi-account-circle-outline ")])],1),_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"13px","color":"#1e1b1b"}},[_c('span',[_vm._v(" "+_vm._s(_vm.appUser.name)+" ")])])]),(_vm.appHostel)?_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"13px","color":"#1e1b1b"}},[_c('span',[_vm._v(" "+_vm._s(this.appHostel)+" ")])])]):_vm._e(),_c('v-flex',{attrs:{"xs12":"","pt-2":"","px-2":""}},[_c('v-btn',{attrs:{"small":"","block":"","dark":"","dense":"","color":"#766BC0"},on:{"click":_vm.appLogout}},[_vm._v(" log out ")])],1),_c('v-flex',{attrs:{"xs12":"","pa-2":""}},[_c('v-btn',{attrs:{"small":"","block":"","dark":"","dense":"","color":"#766BC0"},on:{"click":function($event){_vm.changePassdialogue = true}}},[_vm._v(" Change Password ")])],1)],1)],1)],1)],1)],1),_c('v-layout',{staticClass:"hidden-lg-and-up",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs1":"","align-self-center":""}},[_c('v-app-bar-nav-icon',{style:(_vm.$route.path != '/HostelWarden'
                  ? ' background-color:#766BC0 '
                  : ' background-color: white'),attrs:{"color":_vm.$route.path == '/HostelWarden' ? 'black' : 'white'},on:{"click":function($event){_vm.nav = true}}}),_c('v-navigation-drawer',{attrs:{"height":"450px","absolute":"","temporary":""},model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}},[_c('v-layout',{staticClass:"menufont1",attrs:{"wrap":"","justify-center":"","pa-3":""}},[_c('v-flex',{attrs:{"xs12":""}},_vm._l((_vm.outpassItems),function(main,i){return _c('v-list',{key:i,attrs:{"dense":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[(main.dropdown)?_c('v-list-group',{attrs:{"no-action":"","value":false,"active-class":"activated"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('span',{staticClass:"text-none"},[_vm._v(_vm._s(main.title))])])]},proxy:true}],null,true)},[(main.dropdown)?_vm._l((main.dropdown),function(sub,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-left":"","pl-6":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":sub.route}},[_c('span',[_c('span',{staticClass:"text-none"},[_vm._v(" "+_vm._s(sub.title)+" ")])])])],1)],1)],1)}):_vm._e()],2):_c('v-list-group',{attrs:{"no-action":"","value":false,"active-class":"activated"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":main.route}},[_c('span',{staticClass:"text-none"},[_vm._v(_vm._s(main.title))])])],1)]},proxy:true}],null,true)})],1)],1)],1)}),1)],1)],1)],1),_c('v-flex',{staticClass:"text-left pl-2 pt-1 nsbold hidden-xs-only",staticStyle:{"font-size":"18px","font-weight":"500"},style:(_vm.$route.path != '/HostelWarden'
                ? ' color:white '
                : ' color: black'),attrs:{"xs6":"","sm9":""}},[_vm._v("St.Vincent Boarding House ")]),_c('v-flex',{staticClass:"text-left pl-2 pt-1 nsbold hidden-sm-and-up",staticStyle:{"font-size":"18px","font-weight":"500"},style:(_vm.$route.path != '/HostelWarden'
                ? ' color:white '
                : ' color: black'),attrs:{"xs6":"","sm8":""}},[_vm._v("SVBH ")]),_c('v-flex',{attrs:{"xs3":"","sm2":"","px-4":""}},[_c('v-btn',{staticClass:"nsbold white--text",attrs:{"text":""},on:{"click":function($event){return _vm.appLogout()}}},[_c('span',{style:(_vm.$route.path != '/HostelWarden'
                    ? ' color:white '
                    : ' color: black')},[_vm._v(" LOGOUT ")])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"400px"},model:{value:(_vm.changePassdialogue),callback:function ($$v) {_vm.changePassdialogue=$$v},expression:"changePassdialogue"}},[_c('v-card',{attrs:{"width":"400px"}},[_c('v-toolbar',{staticClass:"body-2",attrs:{"dark":"","color":"#766BC0","dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" Change Password ")])],1),(_vm.currentPHN)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","px-4":"","py-2":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Phone","disabled":""},model:{value:(_vm.currentPHN),callback:function ($$v) {_vm.currentPHN=$$v},expression:"currentPHN"}})],1),_c('v-flex',{attrs:{"xs12":"","px-4":"","pb-2":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Enter current password"},on:{"change":function($event){return _vm.checkPass()}},model:{value:(_vm.oldpass),callback:function ($$v) {_vm.oldpass=$$v},expression:"oldpass"}})],1),_c('v-flex',{attrs:{"xs12":"","px-4":"","pb-2":""}},[_c('v-text-field',{attrs:{"disabled":_vm.res == false ? true : false,"outlined":"","dense":"","label":"Enter new password"},model:{value:(_vm.newpass),callback:function ($$v) {_vm.newpass=$$v},expression:"newpass"}})],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"pt-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.changePassdialogue = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.changePass()}}},[_vm._v("OK")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":1000,"value":true,"centered":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.msg))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }