import Vue from "vue";
import Vuex from "vuex";
import router from "./router";

Vue.use(Vuex);
export default new Vuex.Store({

  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    userData: {},
    appLoading: false,
    email:null,
    userHostel:{},
    blockId:null,

  },
  mutations: {

    SOCKET_() {
      // do something
      alert("hi")
  },
    loginUser(state, payload) {
      localStorage.setItem("token", payload);
      state.isLoggedIn = true;

      if (state.isLoggedIn == true && state.userType == 'AssetAdmin') {
        router.push("/assetAdmin") 
      }
      else if (state.isLoggedIn == true && state.userType == 'HostelWarden') {
        router.push("/HostelWarden") 
      }

      
      else if (state.isLoggedIn == true && state.userType == 'Hostel Manager') {
        router.push("/HostelManager") 
      }
      else if (state.isLoggedIn == true && state.userType == 'viceprincipal') {
        router.push("/vicePrincipal") 
      }
      else if (state.isLoggedIn == true && state.userType != 'AssetAdmin' && state.userType != 'HostelWarden') {
        router.push("/admin") 
      }
      

    },
    logoutUser(state) {
      state.userType = null;
      state.isLoggedIn = false;
      state.userData = {};
      state.userHostel = {};
      state.initial = "";
      localStorage.removeItem("userType");
      localStorage.removeItem("token");
      router.push("/loginnew");

    },
    userType(state, payload) {
      state.userType = payload
      localStorage.setItem("userType", payload)
    },
    userData(state, payload) {
      state.userData = payload 
    },
    blockId(state, payload) {
      state.blockId = payload 
    },
    userHostel(state, payload) {
      state.userHostel = payload 
      // console.log("hostel=",payload)
    }
    
  },
  actions: {
    SOCKET_() {
        // do something
        alert("Actions")
    }
}
});
