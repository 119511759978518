<template>
  <v-app class="nsbold">
    <v-snackbar
      :timeout="3000"
      :value="true"
      absolute
      top
      centered
      color="red"
      v-model="snackbar"
    >
      <span style="font-size: 20px"> {{ msg }}</span>
    </v-snackbar>
    <Header v-if="$route.path != '/' && appType == 'Admin'" />
    <Lheader v-if="$route.path != '/' && appType == 'laundryadmin'" />
    <Aheader v-if="$route.path != '/' && appType == 'AssetAdmin'" />
    <Oheader v-if="$route.path != '/' && appType == 'HostelWarden'" />
    <HMheader v-if="$route.path != '/' && appType == 'Hostel Manager'" />
    <Vheader v-if="$route.path != '/' && appType == 'viceprincipal'" />

    <Banner
      v-if="
        $route.path != '/' &&
        $route.path == '/admin' &&
        appType == 'laundryadmin'
      "
    />
    <Fooddash v-if="$route.path != '/' && appType == 'foodstockadmin'" />
    <!-- <Fooddash v-if="$route.path != '/' && appType == 'foodstockadmin' || appType == 'AssetAdmin'"  /> if same header used for food and asset-->


    <router-view class="nsregular" :style="$route.path == '/' ? '' : 'min-height:90vh'">
    </router-view>

    <!-- <Footer v-if="$route.path != '/'"/> -->
  </v-app>
</template>

<script>
import "./assets/style/fonts.css";
import "./assets/style/style.css";
import Header from "./layout/dashboard";
import Lheader from "./layout/laundryDashboard";
import Aheader from "./layout/assetHeader";
import Oheader from "./layout/outpassHeader";
import HMheader from "./layout/hmHeader";
import Vheader from "./layout/vicePrincipalHeader";
import Banner from "./layout/banner";
import Fooddash from "./layout/fDash.vue";

// import Footer from './common/Footer';
import store from "./store";
import axios from 'axios'

export default {
  name: "App",

  components: {
    Fooddash,
    Header,
    Lheader,
    Aheader,
    Oheader,
    HMheader,
    Vheader,
    Banner,
    // Footer
  },
  //  beforeMount() {
  //     if (typeof localStorage.getItem("token") == "string") {

  //       axios({
  //         method: "get",
  //         url: "/user/admin/get",
  //         headers: {
  //           token: localStorage.getItem ("token"),
  //         },
  //       })
  //         .then((response) => {
  //           if (response.data.status) {
  //             store.commit("appLoading", false);
  //             store.commit("userData", response.data.data);
  //             store.commit("userType", response.data.data.role);

  //           }

  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   },
  data: () => ({
    snackbar: false,
    msg: null,
    //
  }),
  computed: {
    appType() {
      return store.state.userType;
    },
    appUser() {
      return store.state.userData
    }
  },
  mounted() {
    this.getMe()
    var audio = new Audio(require("./assets/notification.mp3"));
    this.$socket.on("connect", () => {
      var tkn = localStorage.getItem("token");
      if (tkn) {
        
        this.$socket.emit("auth", { token: tkn });
      } 
    });
    // this.sockets.subscribe("auth", (data) => {
    // });
    this.sockets.subscribe("New_Purchase", () => {
      this.msg = "Teste";
      this.snackbar = true;
    });
    // this.sockets.subscribe("cab_panic_new", () => {
    //   this.showsnackbar1 = true;
    // });
    this.sockets.subscribe("New_Purchase", () => {
      audio.play();
    });
    //  this.sockets.subscribe("cab_panic_new", () => {
    //   audio.play();
    // });
  },
  methods: {
    getMe() {
      axios({
        method: "post",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) { 
             store.commit("userData",response.data.data);
             store.commit("userType",response.data.role);
             store.commit("userHostel",response.data.hostelname);
            //  if(response.data.blockId){
             store.commit("blockId",response.data.blockId);
            //  }
            // store.commit("loginUser",response.data.token);
            // store.commit("userData",response.data.user);
          }
        })
        .catch((err) => {
          console.log(err());
        });
      
            
    }
  }
};
</script>
