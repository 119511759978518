import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import { ImageBarcodeReader } from "vue-barcode-reader";
import { StreamBarcodeReader } from "vue-barcode-reader";
import VueApexCharts from 'vue-apexcharts'
import ServerError from './components/500'
// import './stylus/main.styl'
import store from './store'

//date time picker
import DatetimePicker from 'vuetify-datetime-picker'
// import './components/stylus/main.styl'
Vue.use(DatetimePicker)

import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client'

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO('https://api.chavaratrust.org'),


  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  // options: { path: "/my-app/" } //Optional options
}))

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(ImageBarcodeReader);
Vue.use(StreamBarcodeReader);
import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('ServerError', ServerError)

// Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://localhost:5050'
// Vue.prototype.baseURL = 'http://localhost:5050';
// axios.defaults.baseURL = 'http://192.168.52.222:5050'
// Vue.prototype.baseURL = 'http://192.168.52.222:5050';
axios.defaults.baseURL = 'https://api.chavaratrust.org'
Vue.prototype.baseURL = 'https://api.chavaratrust.org';
Vue.prototype.mediaURL = 'https://api.chavaratrust.org/file/get/';
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app') 
